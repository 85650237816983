.mainContainer{
    position:absolute;
    width:100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.mainContainer .searchButton{
    margin-top: 10%;
    width: 100%;
    border: 1px solid;
    background-color: #7dbd22;
    border-color: white;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.mainContainer .searchButton:hover{
    background-color: #61941b;
    cursor: pointer;
    border: 0;
}

