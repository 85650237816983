.appBody {
  display: flex;
  flex-direction: column;
}

.formContainer {
  color: var(--white);
  width: 80%;
  margin: 20px 10%;
  justify-content: center;
  background-color: var(--principal-color);
  box-shadow: 3px 3px 9px #aca6a6;
  border-radius: 10px;
}

.mainContainerCalendar {
  position: absolute;
  width: 100%;
}

  .hourTypeContainer {
    width: 90%;
    margin: 0% 5%;
    display: flex;
    flex-direction: column;
    font-size: x-large;
  }

  .hourTypeContainer h3 {
    color: white;
    margin-left: 1%;
  }

  .calendarContainer {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    width: 90%  ;
  }

  .formContainer input {
    width: 100%;
    padding: 0;
    height: 38px;
    margin: 0;
    margin-top: 5px;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    background-color: var(--gray-100);
    color: var(--gray-800);
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.formContainer input:focus,
.formContainer select:focus {
    outline: none;
    border: 2px solid var(--focus-ring-color);
}

.formContainer select {
    width: 100%;
    margin-top: 5px;
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    background-color: var(--gray-100);
    color: var(--gray-800);
    border-radius: 10px;
    align-self: center;
}

.formContainer button {
    cursor: pointer;
    width: 100%;
    height: 40px;
    align-self: center;
    margin-top: 40px;
    font-size: 15px;
    border-radius: 10px;
    background-color: var(--gray-700);
    border: 2px solid var(--gray-700);
    color: white;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    line-height: initial;
    transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
      transform 200ms ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    vertical-align: middle;
  }

.formContainer button:hover{
    background-color: var(--gray-600);
    border: 0
}

.formContainer button:disabled{
    background-color: var(--gray-600);
    border: 0;
    color: var(--gray-200);
}

.formContainer label{
    color: white;
    font-weight: bold;
  }

.formContainer form {
    position: relative;
    width: 90%;
    flex-direction: auto;
    display: flex;
    justify-content: space-evenly;
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 'form-field form-field form-field form-field form-field';
    column-gap: 10px;
    margin: 0% 5%;
}

.formContainer form .label{
  margin-left:0%;
}



.formContainer .form-field {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    display: flex;
}

.docContainer{
    width: 80%;
    background-color: white;
    border-radius: 10px
}

.hoursContainer{
    width: 80%;
    background-color: white;
    border-radius: 10px;
    text-align: center;
}

.calendarElement{
  flex: 4;
  display: flex;
}

.hourCard{
    background-color: #079ae7;
    margin: 6%;
    padding: 3%;
    height: 70px;
    box-shadow: 3px 3px 9px #9d9495;
    border-radius: 10px;

}

.hourCard p{
    color: white;
    font-weight: bold;
    font-size: 0.8em;
}

.article{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    place-items: center;
    width: 100%;
    height: 150px;
    justify-content: initial;
}

.formContainer .hourType{
    min-width: 130px;
    cursor: pointer;
    width: 30%;
    height: 40px;
    margin-top: 10px;
    font-size: 15px;
    border-radius: 7px;
    background-color: white;
    border: 2px solid var(--gray-700);
    color: black;
    text-decoration: none;
    font-weight: bold;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    line-height: initial;
    transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
      transform 200ms ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    vertical-align: middle;
}

.formContainer .hourTypeSelected{
    min-width: 130px;
    cursor: pointer;
    width: 30%;
    height: 40px;
    margin-top: 10px;
    font-size: 15px;
    border-radius: 7px;
    background-color: var(--gray-700);
    border: 2px solid var(--gray-700);
    color: white;
    text-decoration: none;
    font-weight: bold;
    /* margin-bottom: 1rem; */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    line-height: initial;
    transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
      transform 200ms ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    vertical-align: middle;
}

.formContainer .hourType:hover{
    background-color: var(--gray-700);
    border: 0;
    color: white
}

.formContainer .hourTypeSelected:hover{
    background-color: var(--gray-700);
    border: 0;
    color: white
}

.weekStartEndLabel{
  position: absolute;
  text-align: center;
  width: 79%;
  display: flex;
  justify-content: flex-end;
  /* margin-left: 5%; */
}

.leftArrow{
  margin-top: -175px;
  position: absolute;
  left: 10%;
  z-index: 10;
}

.rightArrow{
  margin-top: -175px;
  position: absolute;
  right: 10%;
  z-index: 10;
}

.loader {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.hourInfoContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  flex-direction: column;
  flex: 2;
  /* height: 12vh; */
  background-color: white;
}

.upperInfoContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 1;
  padding-top: 2%;
  justify-content: space-between;
}

.lowerInfoContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 1;
  padding-bottom: 2%;
  justify-content: space-between;
}


.upperRightContainer {
  width: 25%;
  margin-right: 4%;
  margin-left: 4%;
}

.upperLeftContainer {
  width: 75%;
  margin-left: 4%;
}




/* @media only screen and (max-width: 900px){
  .formContainer form{
    grid-template-columns:1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
    'form-field'
    'form-field'
    'form-field'
    'form-field'
    'form-field';
  }
  .formContainer form .label{
    margin-left: 0%;
  }

  .calendarElement{
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
  }

  .leftArrow{
    left: 13%;
    /* margin-top: 0%;
    margin-top: -130px;
  }

  .rightArrow{
    right: 12%;
    margin-top: -130px;
  }

  .upperRightContainer {
    position: absolute;
    right: 10%;
  }


 } */


 @media only screen and (min-width: 700px){

  .leftArrow{
    left: 13%;
    margin-top: -140px;
  }

  .rightArrow{
    right: 12%;
    margin-top: -140px;
  }

 }

 @media only screen and (min-width: 847px){

  .hourInfoContainer {
    width: 100%;
    /* margin-left: 20%; */
  }

  .leftArrow{
    left: 13%;
    margin-top: -150px;
  }

  .rightArrow{
    right: 12%;
    margin-top: -150px;
  }

 }

 @media only screen and (min-width: 1000px){

  .leftArrow{
    left: 8%;
    margin-top: -160px;
  }

  .rightArrow{
    right: 7%;
    margin-top: -160px;
  }

 }


/* This is the calendar css */

/*
.css-10qkmc3 {
  width: 13.74% !important;
  height: 60px !important;
  margin: 0 0px !important;
  color: white !important;
  font-size: 15px !important;
  font-weight: bold !important;
} */

/* .css-i6bazn {
  background-color: white;
  border-radius: 10px;
  height: 108px;
}

.css-1fvxlgl {
  width: 13.74% !important;
  height: 19.23% !important;
  align-items: center !important;
  justify-content: center !important;
}

.css-1cw4hi4 {
  display: none !important;
}

.css-1v994a0 {
  font-size: calc(10px + .4vw) !important;
  pointer-events: none;
}

.css-sldnni {
  display: none !important;
}

.css-mvmu1r {
  margin: 0 !important;
}

.css-16j77m4{
  font-size: calc(10px + .4vw) !important;
  pointer-events: none !important;
}

.css-1wjkg3{
  display: none !important;
}

.css-16j77m4 {
  pointer-events: none !important;
} */
