footer {
  background-color: #5bb543;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1vh;
  margin-top: auto;
}

/* footer .colorBar {
  background-color: #5bb543;
  width: 100%;
  height: 5px;
  position: absolute;
  left: 0;
  top: 0;
} */

/* footer .left {
  display: flex;
  justify-content: center;
  width: 50%;
  height: 100%;
}

footer .right {
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* @media only screen and (min-width: 500px) {
  footer {
    width: 40%;
  }
} */