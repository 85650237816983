.buttons {
  display: flex;
  justify-content: space-between;
  width: 90%;

}

button {
  font-size: large;
  width: 80%;
  border-radius: 4px;
}

.thisTitle {
  height: 5vh;
  text-align: center;
  font-size: 18px;
  color: var(--dark);

}

.buttonCancelReservation {
  width: 80% !important;
}

.box {
  /* width: 400px;
  background-color: var(--light);
  -webkit-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
  box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
  align-items: center;
  flex: none;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 4%;
  padding-right: 4%;
  position: relative;
  margin-top: -9.5vh;
  height: auto;
  margin-bottom: 5%; */
  margin-top: 5%;
}

@media only screen and (min-width: 490px) {
  .box {
    width: 400px;
    background-color: var(--light);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    -moz-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    align-items: center;
    flex: none;
    padding-top: 4%;
    padding-bottom: 5%;
    padding-left: 4%;
    padding-right: 4%;
    position: relative;
    margin-top: -9.5vh;
    height: auto;
    margin-bottom: 5%;
  }
}