.popup {
  position: fixed;
  z-index: 999;
}

.popup-content{
    width: 460px;
    max-width: 500px;
    overflow-y: scroll;
    max-height: 90vh;
    border-radius: 10px;
    align-items: flex-start;

}

#popup-root {
  scrollbar-color: rgb(1, 116, 182) white;
  scrollbar-width: thin;

}

.modal {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    background-color: rgb(81, 181, 67);
    border-radius: 10px;

}

.modal > .content {
    margin-bottom: 5%;
    width: 100%;
    display: flex;
    flex: 6;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(81, 181, 67);
    border-radius: 0px;
}
.modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal .confirmButtonCancel{
    background-color : rgb(81, 181, 67);
    border: solid 1px white;
    font-weight: bold;
    width: 100%;
    color: white;
    border-radius: 20px;
    font-size: calc(10px + .35vw);
}

.modal .confirmButtonReserva{
    background-color : rgb(1, 116, 182);
    border: solid 1px rgb(1, 116, 182);
    font-weight: bold;
    width: 100%;
    color: white;
    border-radius: 20px;
    font-size: calc(10px + .35vw);
}

.modal .confirmButtonCancel:hover{
    cursor: pointer;
    background-color: white;
    border-color: white;
    color: rgb(67, 152, 56);
}

.modal .confirmButtonReserva:hover{
    cursor: pointer;
    background-color: white;
    border-color: white;
    color: rgb(1, 116, 182);
}

.modal .titleModalContent{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2%;
}

.titleModalContent .titleModal {
    margin-left: 3%;
    font-weight: 600;
    font-size: calc(10px + .6vw);
    text-align: center;
    color: #747474;

}

.subTitleText{
    color: white;
    font-size: 27px;
    font-weight: bold;
    align-self: center;
}

.body{
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 85%;
    justify-content: space-evenly;
    background-color: rgb(81, 181, 67);
    border-radius: 10px;
    padding-left: 2%;
    padding-right: 2%;
    margin-bottom: 2%;
    height: auto;
}

.body .titleBodyPopUp {
    display: flex;
    justify-content: center;
    height: 12vh;
    flex-direction: column;
    margin-top: 1%;
    margin-bottom: 2%;
    background-color: white;
    border-radius: 10px;
}

.titleBodyPopUp .dateHour {
    text-align: center;
    color: rgb(81, 181, 67);
    font-size: 22px;
    margin: 0;
}

.titleBodyPopUp .titleBody {
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 20px;
    margin: 0;
}

.popup-overlay{
    /* overflow-y: scroll; */
    background: rgba(52, 52, 52, 0.7);

}

.popup-overlay .popUpConfirmation-overlay ::-webkit-scrollbar {
    display: none;
    border-top-right-radius: 10px;
}

.rowInfoConfirmation {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 10%;
    /* height: 45px; */
    background-color: rgb(81, 181, 67);
    border-radius: 15px;
    font-size: calc(13px + .35vw);
    position: relative;
    margin-bottom: 1%;
    margin-top: 1%;
}

.rowInfoConfirmation div{
    color: white;
    margin-left: 5%;
    margin: 1.5%;
}

.rowInfoConfirmation div .dataLabelTitle{
    color: rgb(170, 230, 155);
    margin-top: 0px;
    margin-bottom: 0px;
}

.iconContainer {
    width: 10%;
    display: flex;
    justify-content: center;
}

.titleTextLeft{
    color: #51b543;
    font-size: 24px;
    font-weight: bold;
    border-left: solid 1px rgb(81, 181, 67);
    height: 75%;
    display: flex;
    align-items: center;
    padding-left: 5%;
    margin-left: 2%;
}

.titleTextRight{
    color: #51b543;
    font-size: 24px;
    font-weight: bold;
    border-left: solid 1px rgb(81, 181, 67);
    height: 75%;
    display: flex;
    align-items: center;
    margin-left: 2%;
    padding-left: 5%;
    justify-content: flex-end;
}

.scrollrowInfoConfirmation{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-height: 4rem;
    background-color: rgb(149, 205, 132);
    border-radius: 15px;
    font-size: calc(10px + .35vw);
    position: relative;
    margin-top: 2%;
    margin-bottom: 3%;
    overflow-y: scroll;
}

.scrollrowInfoConfirmation div{
    position: relative;
    color: rgb(67, 152, 56);
    font-weight: bold;
    margin-left: 5%;
    margin: 3%;
    margin-top: 5%;
    height: 4rem;
}

.buttom {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

/* ::-webkit-scrollbar {
    width: 10px;
    border-top-right-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */

@media only screen and (max-width: 500px){
    .popup-content{
        width: 85vw;
    }
}

@media only screen and (max-width: 600px){
  .titleTextLeft{
    font-size: 19px;
  }
  .titleTextRight{
    font-size: 19px;
  }
  .subTitleText{
    font-size: 22px;
  }
}