.img {
    align-self: center;
    width: 26%;
    height: 20%;
    margin-top: 2%;
    margin-bottom: 2%;
}

@media only screen and (max-width: 500px) {
    .img {
        width: 90%;
        height: 20%;
    }
}


@media only screen and (max-width: 900px) and (min-width: 500px) {
    .img {
        width: 65%;
        height: 20%;
    }
}