.item{
    font-size: calc(8px + .75vw) !important;
    display: flex;
    justify-content: center;
    background-color: #E0E0E0 !important;
    box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2) !important;
    color: #717171 !important;
    padding: 0px !important;
    font-size: 14px !important;
    /* 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) */

}

/* .root{
    background-color: red;
} */

.itemModule{
    font-size: 10px;
}

.itemModule:hover{
    background-color: #0075b3;
    cursor: pointer;
}

.item:hover{
    background-color: #0075b3 !important;
    cursor: pointer !important;
    color: white !important;
}

.disabledItem{
    font-size: 10px;
    background-color: #5ec0f5 !important;
    cursor: not-allowed;
    color: #ffffffcf !important;
}


.docInfo {
    color: #5ab542;
    font-weight: bold;
    height: 20px;
    background-color: white;
    font-size: calc(15px + .15vw);
    display: flex;
    align-items: center;
    margin-left: 5%;
    margin-top: 1%;
}

.docSpecialty{
    margin-left: 5%;
    color: #9a9a9a;
    font-size: calc(11px + .22vw);
}


.nextPageButton{
    height: calc(15px + .9vw);
    width: 100%;
    background-color:#e6e6e6;
    border-radius: 3px;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 0;
    cursor: pointer;
    font-size: 20px;
    padding: calc(4px + .2vw);
    height: 20.02px;

    /* border-top-left-radius: 5px; */
}

.nextPageContainer{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
}

.nextPageButton:hover{
    background-color: #1a73a6;
    color: white;
}

.nextPageButton:hover .icon{
    color: white;
}

.hourContainer{
    background-color: white;
    margin-bottom: 40px;
    border-radius: 10px;
    /* overflow: scroll; */
}

.docContainer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex: 1;
    /* align-items: center; */
    flex-direction: column;
    margin-top: 1%;
}

.css-1vtmjk6-MuiGrid-root{
    flex: 3;
}

.icon{
    color: #1a73a6;
    padding: 0;
    font-size: 24px;
    /* size: 40px; */
}

.icon:hover{
    color: #e6e6e6;
}

.noProfessionalHourContainer {
    height: auto;
    background-color: white;
    margin-bottom: 40px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
}

.hourCardBody {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    width: 100%;
}

.noProfessionalHourBodyColumn {
    display: flex;
    flex: 1;
    margin-left: 3%;
    margin-right: 3%;
    flex-direction: column;
}

.bottomCard {
    /* display: flex; */
    flex: 1;
    padding-top: 8px;
    padding-left: 8px;
    max-width: 23%;
}

.bottomCardDisplayNone {
  display: none
}

.backButton {
    width: 16%;
    margin-right: 5%;
    color: white;
    background-color: #78cd51;
    height: 20%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgDoc {
    height: calc(90px + .3vw);
    width: calc(90px + .3vw);
    border: 2px solid;
    border-color: white;
    border-radius: 50%;
    /* margin-bottom: 8%; */
    /* padding: 4%; */
}

.hourProfessionalCard {
    display: flex;
    flex: 1.5;
    justify-content: center;
    flex-direction: column;
    flex-direction: row;
}

.gridHourCard {
    padding-left: 4%;
    margin-top: 5% !important;
    padding-right: 7%;
}

.imageDocContainer {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
  padding: 4%;
}

@media only screen and (max-width: 420px){
  .item {
    font-size: 11px !important;
  }

  .nextPageButton{
    height: 17.16px;
  }

  .imageDocContainer {
    padding: 2%;
  }

  .icon {
    font-size: 19px;
  }

}

@media only screen and (max-width: 384px){
  .bottomCard {
    max-width: 22%;
  }

}
