.appBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.filterContainer {
  position: relative;
  color: var(--dark);
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  /* justify-content: center; */
  flex: 6;
  border-radius: 25px;
  /* margin-top: 2%; */
}

.error {
  text-align: center;
  color: var(--error);
}

.filterAgeField {
  width: 90%;
  border-radius: 20px;
}

button[type="submit"] {
  margin-top: 5%;
  width: 60%;
  background-color: var(--principal-color);
  color: var(--light);
}

button[type="submit"]:hover {
  background-color: var(--hover-color);
}

button[type="submit"]:disabled {
  background-color: var(--disabled-color);
  color: var(--disabled-light);
}

.conten-button {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40%;
}

.filterFormContainer {
  flex: 5;
  border-radius: 5px;
  width: 100%;
}

/* Desde aqui es solo de filter */
.form {
  margin-top: 5%;
  position: relative;
  width: 100%;
  height: 80%;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  background-color: transparent;
  justify-items: center;
}

.buttonSelectBar {
  /* height: 200px; */
  position: relative;
  min-height: 44px;
  width: 100%;
  display: flex;
  border-radius: 1px;
  margin: 1%;
  border: solid;
  border-width: 1px;
  border-color: #5ab542;
  flex: 1;
}

.buttonSelectBar button {
  padding: 1%;
  font-size: medium;
  margin: 0%;
}

.buttonNormal {
  color: #5ab542;
  border: 0px;
  border-radius: 1px;
}

.buttonNormalSelected {
  background-color: #5ab542;
  color: rgb(255, 255, 255);
  border: 0px;
  border-radius: 1px;
  font-weight: 700;
}

.buttonNormal:hover {
  background-color: rgb(216, 216, 216);
}

.buttonDoctor {
  color: #5ab542;
  border: 0px;
  border-radius: 1px;
  /* border-radius: 0px 5px 5px 0px; */
}

.buttonDoctorSelected {
  background-color: #5ab542;
  color: rgb(255, 255, 255);
  border: 0px;
  border-radius: 1px;
  font-weight: 700;
}

.buttonSubmit {
  align-self: center;
  background-color: #129cbb;
  border-color: white;
  border-width: 1px;
  color: white;
  width: 150px;
  cursor: pointer;
}

.buttonSubmit:hover {
  background-color: #0c5e71;
}
.titleContainer {
  padding-top: 40px;
  width: 95%;
  margin: 0 auto;
  color: var(--light);
  display: flex;
  justify-content: center;
}

.filterMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 3%;
}

.containerTitle {
  font-size: x-large;
  color: #5ab542;
  display: flex;
  align-items: center;
  font-weight: 600;
  width: 80%;
  justify-content: center;
  flex: 1;
  margin-top: 2%;
}

.headContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  color: white;
}

.goBackButton {
  position: absolute;
  left: 0%;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: #5ab542;
  border-radius: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5%;
  margin-top: 2%;
  margin-left: 5%;
}

.containerSubTitle {
  /* font-size:x-large; */
  margin-top: 3%;
  color: #989898;
  display: flex;
  align-items: center;
  font-weight: 400;
  text-align: center;
  flex: 1;
  margin-bottom: 5%;
  min-height: 61px;
}

.formSelector {
  -webkit-appearance: none;
  margin-bottom: 3%;
  appearance: none;
  background-color: white;
  border-radius: 10px;
  color: var(--gray-800);
  font-size: 1rem;
  /* margin: 5px; */
  /* padding: .65rem .5rem; */
  width: 100%;
}

.formSelector svg {
  color: #0174b5;
}

input.formSelector {
  border-radius: 6px;
  padding: 0;
  width: 97.4%;
  height: 40px;
  padding-left: 2.5%;
  margin: 0;
}

input.formSelector:hover {
  border-color: none;
}

input.formSelector:focus {
  outline: none;
}

input.formSelector::placeholder {
  color: #80808060;
  font-weight: lighter;
  font-size: 14px;
}

.fullScreen {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 90%;
  /* padding-bottom: auto; */
  margin-bottom: 1vh;
  margin-top: 3%;
}

@media only screen and (min-width: 490px) {
  .filterMainContainer {
    width: 420px;
    background-color: var(--light);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    -moz-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    align-items: center;
    flex: none;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 4%;
    padding-right: 4%;
    position: relative;
    margin-top: -9.5vh;
    height: auto;
    margin-bottom: 5%;
  }

  .fullScreen {
    width: 420px;
    background-color: var(--light);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    -moz-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    align-items: center;
    flex: none;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 4%;
    padding-right: 4%;
    position: relative;
    margin-top: -9.5vh;
    height: auto;
    margin-bottom: 5%;
  }

  .filterContainer {
    width: 83%;
  }

  .filterFormContainer form {
    height: 95%;
  }

  .containerSubTitle {
    width: 83%;
  }
}

@media only screen and (min-width: 600px) {
  .filterMainContainer {
    width: 420px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .fullScreen {
    width: 420px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
}

@media only screen and (min-width: 800px) {
  .filterMainContainer {
    width: 420px;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .fullScreen {
    width: 420px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
}

@media only screen and (min-width: 1000px) {
  .filterMainContainer {
    width: 420px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .fullScreen {
    width: 420px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
}

@media only screen and (max-width: 500px) {
  .filterContainer {
    width: 92%;
  }
}

@media only screen and (max-width: 490px) {
  .goBackButton {
    top: 1%;
    background-color: transparent;
  }
}
