@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

/* COMMON CONFIGURATIONS*/

.lds-ring {
  margin: 0 auto;
}

.hourReservationContainer {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--light);
  color: var(--dark);
}

section {
  border-radius: 25px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 83%;
}

.containerTitle {
  font-size: 31px;
  color: #5ab542;
  display: flex;
  align-items: center;
  /* font-weight: 900; */
  width: 100%;
  justify-content: center;
  flex: 1
}

.labelForm {
  color: #5ab542;
  font-weight: 600;
  text-align: left;
  font-size: 15px;

}

.mainContainerRutPage {
  /* width: 70%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-self: center;
  align-items: center;
  flex: 7;
  padding-top: 3%;
}

.containerSubTitle {
  /* font-size:x-large; */
  color: #3A3A3A;
  display: flex;
  align-items: center;
  font-weight: 400;
  text-align: center;
  flex: 1;
  margin-bottom: 4%;
  width: 88%;
  justify-content: center;
  font-size: 18px;
}

.rutInputContainer {
  display: flex;
  justify-content: center;
  width: 70%;
}

.fieldForm {
  height: 40px;
  padding: 0;
  width: 98%;
  padding-left: 2%;
  border-radius: 7px;
  margin-bottom: 2%;
}

.fieldForm:hover {
  border-color: none;
}

.fieldForm:focus {
  outline: none;
}

.fieldForm::placeholder {
  color: #80808060;
  font-weight: lighter;
  font-size: 14px;
}

.selectForm {
  margin: 0;
  padding: 0;
  height: 40px;
  margin-bottom: 3%;
  width: 98.8%;
  padding-left: 2%;
  -webkit-appearance: none;
  font-weight: 400;
  color: #808080;
}

.select-arrow {
  position: absolute;
  top: 40%;
  right: 3%;
  pointer-events: none;
  color: #5ab542;
}

.selectForm:hover {
  border-color: none;
}

.selectForm:focus {
  outline: none;
}

.rutContainer {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
}

button,
input {
  -webkit-appearance: none;
  appearance: none;
  /* background-color: var(--gray-100); */
  border: 2px solid var(--gray-200);
  border-radius: 6px;
  color: var(--gray-800);
  font-size: 1rem;
  /* margin: 5px; */
  padding: .65rem .5rem;
  width: 80%;
}

.rutDescription {
  font-size: 12px;
  margin-bottom: 3%;
  color: #808080;
  font-weight: 600;
}

button[type="submit"] {
  width: 40%;
  border-radius: 20px;
  background-color: #0174b5;
  color: var(--light);
  align-self: center;
  /* margin-top: 5%; */
}

button[type="submit"]:hover {
  background-color: #02598b;
}

button[type="submit"]:disabled {
  background-color: var(--disabled-color);
  color: var(--disabled-light);
}

input .fieldInput :read-only {
  background-color: var(--not-editable-color);
}

label {
  display: block;
  /* margin-bottom: 1%; */
}

form {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inputContainer {
  /* background-color: var(--white); */
  /* padding: 20px; */
  border-radius: 5px;
  width: 90%;
  flex: 5
}

p {
  padding: 0;
  margin: 0;
}

/* DESKTOP VIEW CONFIGURATIONS*/


@media only screen and (min-width: 490px) {
  .mainContainerRutPage {
    width: 430px;
    background-color: var(--light);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    -moz-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    align-items: center;
    flex: none;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 4%;
    padding-right: 4%;
    position: absolute;
    top: -12%;
    height: 75%;
  }

  .inputContainer {
    display: flex;
  }

  .inputContainer form {
    display: flex;
    justify-content: space-evenly;
  }

}


@media only screen and (min-width: 600px) {
  .mainContainerRutPage {
    width: 420px;
    padding-top: 3%;
    padding-bottom: 3%;
  }
}

@media only screen and (min-width: 1000px) {
  .mainContainerRutPage {
    width: 420px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
}

@media only screen and (min-width: 1200px) {
  .mainContainerRutPage {
    width: 420px;
    padding-top: 1%;
    padding-bottom: 2%;
  }
}

/* @media only screen and (min-width: 500px) {



  .img-header {
    width: 20%;
    height: auto;
  }

  .clientDataContainer {
    padding: 15px;
    width: 800px;
    margin: 0 auto;
    justify-content: center;
    order: 2;
  }

  .inputPair .fieldInput {
    width: 96%;
  }

  select {
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    background-color: var(--gray-100);
    color: var(--gray-800);
    border-radius: 10px;
    margin: 5px;
  }

  select:disabled {
    background-color: var(--not-editable-color);
  }
}


@media only screen and (max-width: 500px) {
  section {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
  }

  .inputContainer {
    margin-top: 10%;
  }

  .containerTitle {
    font-size: 30px;
    margin-top: 8%;
  }

  .containerSubTitle {
    font-size: 18px;
    margin-top: 5%;
    margin-bottom: 6%;
  }

  .img-header {
    width: 60%;
    height: auto;
  }

  .clientDataContainer {
    padding: 15px;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    order: 2;
  }

  .inputPair .fieldInput {
    width: 90%;
  }

  .reservationButton {
    width: 97%;
  }

  select {
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    background-color: var(--gray-100);
    color: var(--gray-800);
    border-radius: 10px;
    margin: 5px;
  }

  .inputContainer {
    background-color: var(--white);
    border-radius: 5px;
    width: 92%;
    /* padding: 10px; */
/* } */

/* .fieldForm{
    margin-bottom: 7%;
  }

  .selectForm {
    margin-bottom: 7%;
  }

  button[type="submit"] {
    width: 70%;
    margin-top: 10%;
  }

}

@media only screen and (min-width: 500px) {
  .inputContainer {
    width: 80%;
  }
}

@media only screen and (min-width: 600px) {

  .inputContainer {
    width: 60%;
  }

} */